import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

// eslint-disable-next-line import-rules/disallow-paths
import type { TippyProps } from '@tippyjs/react';
import type { Instance } from 'tippy.js';

import React from 'react';

// eslint-disable-next-line import-rules/disallow-paths
import Tippy from '@tippyjs/react';

import TooltipContentBox from '@/core/features/tooltip/tooltip-content-box';

import styles from './tooltip.module.scss';

export type TippyConfig = TippyProps;

export type TooltipProps = React.PropsWithChildren<{
    animation?: boolean;
    ariaLabel?: string;
    className?: string;
    content: React.ReactNode;
    flip?: boolean;
    hasCloseButton?: boolean;
    isCloseable?: boolean;
    onClose?: () => void;
    onHide?: () => void;
    placementRef?: Element | React.RefObject<Element> | null;
    qaId?: string;
    strategy?: 'absolute' | 'fixed';
    tippyConfig?: TippyConfig;
    visible?: boolean;
}>;

export default function Tooltip({
    animation = true,
    ariaLabel,
    children,
    className,
    content,
    flip = true,
    hasCloseButton,
    isCloseable,
    onClose,
    onHide,
    placementRef,
    qaId,
    strategy = 'fixed',
    tippyConfig,
    visible,
}: TooltipProps) {
    const [instance, setInstance] = React.useState<Instance>();
    const selfRef = React.useRef<HTMLDivElement>(null);

    const handleClose = () => {
        if (instance) {
            instance.hide();
        }
    };

    return (
        <div
            aria-label={ariaLabel}
            className={className}
            data-qa-id={qaId}
            ref={selfRef}
            role={'tooltip'}
        >
            <Tippy
                animation={animation}
                appendTo={selfRef.current ?? undefined}
                className={styles.tooltip}
                content={
                    <TooltipContentBox
                        content={content}
                        handleClose={onClose ? onClose : handleClose}
                        hasCloseButton={hasCloseButton}
                        isCloseable={isCloseable}
                    />
                }
                delay={150}
                duration={0}
                interactive
                maxWidth={500}
                offset={[0, 0]}
                onClickOutside={onClose}
                onCreate={setInstance}
                onHide={onHide}
                placement={'auto'}
                popperOptions={{ modifiers: [{ enabled: flip, name: 'flip' }], strategy }}
                reference={placementRef}
                theme={'light'}
                visible={visible}
                {...tippyConfig}
                trigger={visible !== undefined ? undefined : (tippyConfig?.trigger ?? 'mouseenter focus')}
            >
                <div>{children}</div>
            </Tippy>
        </div>
    );
}
